<script setup lang="ts">
import { useRandomArtists, useRandomLocations, useRandomRegions } from '~/composables/showcase'

const { data: regions, suspense: regionsSuspense } = useRandomRegions(4)
const { data: locations, suspense: locationsSuspense } = useRandomLocations(1)
const location = computed(() => locations.value?.[0])
const { data: offers, suspense: offersSuspense } = useRandomOffers(4)
const heroMultiLinkPropsOA = useHeroMultiLink('offers_artists')
const { data: artists, suspense: artistsSuspense } = useRandomArtists(1)
const artist = computed(() => artists.value?.[0])

const heroMultiLinkPropsRL = useHeroMultiLink('regions_locations')
onServerPrefetch(() =>
  Promise.all([regionsSuspense(), locationsSuspense(), offersSuspense(), artistsSuspense()]),
)
</script>

<template>
  <div class="flex flex-col gap-36">
    <AlRegionBigSlider
      v-if="regions"
      :regions="
        regions.map((region) => ({
          name: region.name,
          slug: `${region.slug}-${region.slug_id}`,
          thumbnail: {
            alt: region.image_alt,
            src: region.image_url,
          },
        }))
      "
    />

    <AlLocationShowcase
      v-if="location"
      :title="location.name"
      :subtitle="location.subtitle"
      :image="{
        alt: location.image_alt,
        url: location.image_url,
      }"
      :offer-count="location.offer_count"
      :to="`/kunstraum/${location.slug}-${location.slug_id}`"
    />

    <AlOfferShowcaseGrid
      v-if="offers"
      :offers="
        offers.map((offer) => ({
          slug: `${offer.slug}-${offer.slug_id}`,
          thumbnail: {
            alt: offer.image_alt,
            src: offer.image_url,
          },
          name: offer.name,
          subtitle: offer.description,
        }))
      "
    />

    <AlHeroMultiLink v-bind="heroMultiLinkPropsOA" />

    <AlArtistShowcase
      v-if="artist"
      :title="artist.name"
      :subtitle="artist.title"
      :image="{
        alt: artist.image_alt,
        url: artist.image_url,
      }"
      :to="`/kuenstler-in/${artist.slug}-${artist.slug_id}`"
      :offer-count="artist.offer_count"
    />

    <AlHeroMultiLink v-bind="heroMultiLinkPropsRL" />

    <AlContactForm />
  </div>
</template>
